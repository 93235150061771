import React from 'react';

var Banner = function Banner(props) {
  return React.createElement("section", {
    id: "banner",
    className: "major"
  }, React.createElement("div", {
    className: "inner"
  }, React.createElement("header", {
    className: "major"
  }, React.createElement("h1", null, "HOSPITALITY TRIBE")), React.createElement("div", {
    className: "content"
  }, React.createElement("p", null, "Industry focused and forward thinking, Hospitality Tribe is a collective distinguished by our unyielding dedication to hospitality professionals. In 2019, we set forth a mission to provide support, training and resources to make you the very best, for yourself, your guests, and for the next generation of hoteliers and restaurateurs. Welcome to the tribe."))));
};

export default Banner;